import Vue from 'vue'
import Vuex, { Store } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import * as Cookies from 'js-cookie'
Vue.use(Vuex)
import moment from 'moment'
import moment_timezone from "moment-timezone";

var zone_name =  moment.tz.guess();
var timezone = moment.tz(zone_name).zoneName() 
var utc = moment.tz(zone_name).utcOffset()
import axiosApi from "./helpers/axios";


//timezone = "US/Eastern"
//console.log(timezone, 'timezone')
var from = parseInt(moment().tz(zone_name).set({hour:0,minute:0,second:0}).format('X'))
var to =  parseInt(moment().tz(zone_name).set({hour:23,minute:59,second:59}).format('X'))

//var time = moment().format('hh:mm A (HH:mm [HS])')
//var date =  moment().format('dddd, MMM Do YYYY')


const state = {

  refresh_token: "",
  access_token: "",
  loggedInUser: {},
  isAuthenticated: false,

  dialogLeft: 0,
  dialogWidth:0,
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  sidebarNavHeight: 0,
  headerHeight:0,
  user: {
    loggedIn: false,
    data: null
  },
  snackbar: {
    show: false,
    position: 'center',
    duration: 4000,
    isInfinity: false
  },
  timezone: zone_name,
  utc: utc,
  //time: time,
  //date: date,
  statuses: {
    '2eHTRDPnLGfafpyspaLy': 'Cancelled',
    '5E6evdRSaYGebn0QyTEr': 'Offered',
    '5y2pmSkkrYBpWmQCGMKT': 'Partially paid',
    'EjzXPazPlo7b2WO1LvdH': 'Dropped off',
    'Eni0aM7IqPYqG7ZYCPBn': 'Quoted',
    'GyMszTAcBIYXgbsv3eLQ': 'Picked up',
    'IPKFsEucb8kjJueVEJbX': 'Unpaid',
    'JcX77ZF7l2ydDCTMsQiw': 'Confirmed',
    'YSJGDWBb47imDaIUcmMr': 'Assigned',
    'iwhHTUP08eloDnmgGRGx': 'Pre assigned',
    'atIamyJfP8XDbTfNuPNP': 'On the way',
    'b2UwM5EsIfoOvt9clkoA': 'Unassigned',
    'dF6isActI3wRNckjAXiw': 'Paid',
    'ezxqWUGWSa7w0jsZJJLk': 'Unconfirmed',
    'ppoEFhV1VL98AQhUBInf': 'On location',
    'upNRT5qFbkXmjQDn7mtW': 'Late cancellation',
    'y3lWmMTGW7eiP72DSN0F': 'Taken',
    'BzmeahZDs7Fon0kP0R60': 'Accepted',
    '1F8N9hGSUZaIMYh1jIAJ': 'On hold'
  },
  filters: { 
    fromDate: from,
    toDate: to,
    queryFromDate: null,
    queryToDate: null,
    rowsQty:10,
    search:null,
    companyProfile:null,
    perPage: 10,
    page: 1,
    sort: 'day',
    sortOrder: null,
    preset: 'today',
    searchById: false,
    goToID: null,
    last: null,
    first: null,
    direction: null
  },

}


const mutations = {
  centerDialog: function(state, innerWidth){
      
      if(state.sidebarShow === 'responsive' ) {
      //     if(window.innerWidth >= 992) {
      //         if(!this.$store.state.sidebarMinimize) {
      //             this.dialogLeft = 256 
      //         } else {
      //             this.dialogLeft = 56
      //         }
      //     } else {
      //     this.dialogLeft = 0
      
      //     }
       if(innerWidth >= 992) {
        state.dialogLeft = 190
        state.dialogWidth = innerWidth - 190
       
       } else {
        state.dialogLeft = 0
        state.dialogWidth = innerWidth 
       }
        
          
      } else {
        state.dialogLeft = 0
        state.dialogWidth = innerWidth
      //     if(this.$store.state.sidebarShow === false) {
      //         this.dialogLeft = 0
      //     } else {
      //         if(this.$store.state.sidebarMinimize) {
      //             this.dialogLeft = 256
      //         } else {
      //             this.dialogLeft = 256
      //         }

      //     }
          
      }
  },
  setRefreshToken: function(state, refreshToken) {
    state.refresh_token = refreshToken;
  },
  setAccessToken: function(state, accessToken) {
    state.access_token = accessToken;
  },

  // sets state with user information and toggles 
  // isAuthenticated from false to true
  setLoggedInUser: function(state, user) {
    // SET_USER
    // SET_LOGGED_IN
    
    state.loggedInUser = user;
    state.isAuthenticated = true;
  },
  // delete all auth and user information from the state
  clearUserData: function(state) {
    state.refresh_token = "";
    state.access_token = "";
    state.user = {
      loggedIn: false,
      data: null
    }
    // state.loggedInUser = {};
    // state.isAuthenticated = false;
  },
  restoreBrowserTime (state) {
    let zone_name =  moment.tz.guess();
    state.timezone = zone_name
    state.utc = moment.tz(zone_name).utcOffset()
    //state.time = moment().format('hh:mm A (HH:mm [HS])')
   
    //state.date = moment().format('dddd, MMM Do YYYY')
    //state.date = moment().format('dddd DD-MM-YYYY')
  },
  toggleSidebarDesktop (state) {
    
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  setDateFilter (state, [variable, value]) {
    state.filters[variable] = value
  },
  set (state, [variable, value]) {
    state[variable] = value
  },
  SET_LOGGED_IN(state, value) {
    state.user.loggedIn = value;
  },
  SET_USER(state, data) {
    state.user.data = data;
  }
}

const actions = {
  logIn: async ({ commit, dispatch }, payload) => {
    const loginUrl = "v1/auth/jwt/create/";
    try {
      await axios.post(loginUrl, payload).then(response => {
        if (response.status === 200) {
          commit("setRefreshToken", response.data.refresh);
          commit("setAccessToken", response.data.access);
          dispatch("fetchUser");
          // redirect to the home page
          router.push({ name: "home" });
        }
      });
    } catch (e) {
      console.log(e);
    }
  },
  obtainUserResources: async ({
    commit,
    dispatch
  }, payload) => {
      try {
          /////
          let userBase = Vue.prototype.$firebase
              .firestore()
              .collection('users')
              .doc(payload.uid)
          let clients = [];
          console.log('Step 1')

          await userBase
              .collection('clients')
              .onSnapshot((querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                      let dataToSave = doc.data();
                      dataToSave.id = doc.id;
                      clients.push(dataToSave);
                  });
                  console.log(clients)
                  //th.total = clients.length;
              })


          console.log('Step 2')
          for (let i = 0; i < clients.length; i++) {
              clients[i].passengers = []
              let passengers =
                  await userBase
                  .collection('clients')
                  .doc(clients[i].id)
                  .collection('passengers')
                  .get()
              passengers.forEach(doc => {
                  let dataToSave = doc.data()
                  dataToSave.id = doc.id
                  clients[i].passengers.push(dataToSave)
              })
          }

          console.log('Step 3')
          commit("set", ['clients', clients])


          console.log('Step 4')
          let data1 = {
              ownerEmail: payload.email,
              roleToGet: 'driver'
          }
          let drivers = []
          axiosApi.get('drivers', {
              params: data1
          }).then(response => {
              console.log(response)
              drivers = response.data.users;
              commit("set", ['drivers', drivers])
          }).catch(e => {
              console.log(e);
          })
          let data2 = {
              ownerEmail: payload.email,
              roleToGet: 'dispatcher'
          }
          let dispatchers = []
          axiosApi.get('dispatchers', {
              params: data2
          }).then(response => {
              dispatchers = response.data.users;
              commit("set", ['dispatchers', dispatchers])
          }).catch(e => {
              console.log(e);
          })
          let serviceTypes = []
          await userBase
              .collection('service-types')
              .onSnapshot((querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                      let dataToSave = doc.data();
                      dataToSave.id = doc.id;
                      if (dataToSave.vehicleType && dataToSave.vehicleType.length > 0) {
                          serviceTypes.push(dataToSave);
                      }
                  });
                  commit("set", ['serviceTypes', serviceTypes])
              });
          let vehicleTypes = []
          await userBase
              .collection('vehicle-types')
              .onSnapshot((querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                      let dataToSave = doc.data();
                      dataToSave.id = doc.id;
                      let counter = 0;
                      if (dataToSave.rates.addons) {
                          for (let i = 0; i < dataToSave.rates.addons.length; i++) {
                              if (dataToSave.rates.addons[i].enabled && dataToSave.rates.addons[i].price) {
                                  counter++;
                              }
                          }
                      }
                      counter > 0 ? dataToSave.rates.addonsAvailable = true : dataToSave.rates.addonsAvailable = false
                      vehicleTypes.push(dataToSave);
                  });
                  commit("set", ['vehicleTypes', vehicleTypes])
              });
          let companyProfiles = []
          await userBase
              .collection('company-profiles')
              .onSnapshot((querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                      let dataToSave = doc.data();
                      dataToSave.id = doc.id;
                      companyProfiles.push(dataToSave);
                  });
                  commit("set", ['companyProfiles', companyProfiles])
                  // BUG: Replace this in each View
                  // for (let i = 0; i <  companyProfiles.length; i++) {
                  //     if(companyProfiles[i].default) {
                  //         this.selectedData.companyProfile = companyProfiles[i].id
                  //         this.reservation.companyProfile = companyProfiles[i]
                  //         this.updateTime()
                  //         this.flowTimeZone();
                  //     }
                  // }
                  //resolve()
              });
          // Get Merchants
          let merchantAccounts = []
          axiosApi.get('active-merchant-accounts/' + payload.uid).then(response => {
              merchantAccounts = response.data.accounts;
              // for (let i = 0; i <  th.merchantAccounts.length; i++) {
              //     if(th.merchantAccounts[i].default) {
              //         th.payment.merchantAccount = th.merchantAccounts[i].id
              //         th.payment.gateway = th.merchantAccounts[i].gateway
              //     }
              // }
              //resolve()
              commit("set", ['merchantAccounts', merchantAccounts])
          }).catch(e => {
              console.log(e);
              //reject()
          })
          let jobTypes = []
          await Vue.prototype.$firebase
              .firestore().collection('job-types')
              .orderBy('order').onSnapshot((querySnapshot) => {
                  jobTypes = [];
                  querySnapshot.forEach((doc) => {
                      let dataToSave = doc.data();
                      dataToSave.id = doc.id;
                      jobTypes.push(dataToSave);
                  });
                  commit("set", ['jobTypes', jobTypes])
              });
          /////

          let statuses = []
          await Vue.prototype.$firebase
          .firestore()
          .collection('statuses')
          .orderBy('name').onSnapshot((querySnapshot) => {
            let reservationStatuses =  []
            let driverStatuses =  []
            let paymentStatuses =  []
            let tripStatuses =  []
            querySnapshot.forEach((doc) => {

                let dataToSave = doc.data();
                dataToSave.id = doc.id ;
                switch(dataToSave.type) {
                    case 'reservation':
                        reservationStatuses.push(dataToSave);
                        break;
                    case 'driver':
                        driverStatuses.push(dataToSave);
                        break;
                    case 'payment':
                        paymentStatuses.push(dataToSave);
                        break;
                    case 'trip':
                        tripStatuses.push(dataToSave);
                        break;
                    
                }
                
                
            });
            commit("set", ['reservationStatuses', reservationStatuses])
            commit("set", ['driverStatuses', driverStatuses])
            commit("set", ['paymentStatuses', paymentStatuses])
            commit("set", ['tripStatuses', tripStatuses])
            //commit("set", ['statuses', statuses])


        })
      } catch (e) {
          console.log(e);
      }
  },
  refreshToken: async ({ state, commit }) => {
    const refreshUrl = "auth/jwt/refresh/";

      try {

        // await Vue.prototype.$firebase
        // .auth()
        // .currentUser.getIdToken(true)
        // .then(function(idToken) {
          
        //   commit("setAccessToken", idToken);
        // })
        // .catch((e) => {
        //   console.log(e);
        // }); 

        const token = await Vue.prototype.$firebase
        .auth()
        .currentUser.getIdToken(true)
        commit("setAccessToken", token);

      // await axios
      //   .post(refreshUrl, { refresh: state.refresh_token })
      //   .then(response => {
      //     if (response.status === 200) {
      //       commit("setAccessToken", response.data.access);
      //     }
      //   });
    } catch (e) {
      console.log(e.response);
    }
  },

  fetchUser({ commit }, user) {
    commit("SET_LOGGED_IN", user !== null);
    console.log(user)
    if (user) {
      let activeRole = user.claims.activeRole ? user.claims.activeRole : user.claims.role 
      commit("setRefreshToken", user.refreshToken);
      commit("setAccessToken", user.token);
      commit("SET_USER", {
        // displayName: user.displayName,
        // email: user.email,
        // refreshToken: user.refreshToken,
        // uid: user.uid
        activeRole: activeRole,
        activeSubrole: user.claims.activeSubrole,
        roles: user.claims.roles,
        phoneNumber: user.claims.phone_number,
        displayName: user.claims.name,
        email: user.claims.email,
        refreshToken: user.refreshToken,
        token: user.token,
        uid: user.claims.user_id,
        role: user.claims.role,
        subrole: user.claims.subrole,
        ownerEmail: user.claims.ownerEmail,
        ownerUid: user.claims.ownerUid,
        signInProvider: user.signInProvider,
        signInSecondFactor: user.signInSecondFactor,
        code: user.claims.code,
        email_verified: user.claims.email_verified

      });
      
    } else {
      commit("SET_USER", null);
    }
  }
}

const getters = {
  user(state){
    return state.user
  },
  accessToken: state => state.access_token,
  refreshToken: state => state.refresh_token
}

export default new Vuex.Store({
  state,
  mutations,
  getters,
  actions,
  plugins: [
    createPersistedState({
      getState: (key) => Cookies.getJSON(key),
      setState: (key, state) => Cookies.set(key, state, { expires: 3, secure: false })
    })
  ]
})