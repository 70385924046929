import firebase from 'firebase';

const settings = {timestampsInSnapshots: true}; 

const config = {
    apiKey: "AIzaSyAnrSitVXFtQeWZ-UDKhcRF4m_Ao5toJuA",
    databaseURL: "https://uplimoapp.firebaseio.com",
    projectId: "uplimoapp",
    storageBucket: "gs://uplimoapp.appspot.com"
};

firebase.initializeApp(config);

firebase.firestore().settings(settings);

export default firebase;

