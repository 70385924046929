import Vue from "vue";
import Router from "vue-router";

// Containers
const TheContainer = () => import("@/containers/TheContainer");
const WebContainer = () => import("@/web/containers/Container");

// Views
const Dashboard = () => import("@/views/Dashboard");
const UserSettings = () => import("@/views/user-settings/UserSettings");
const Fixer = () => import("@/views/fixer/Fixer");

// Views - offers
const Offers = () => import("@/views/offers/Offers");
const Offer = () => import("@/views/offers/Offer");
const NewOffer = () => import("@/views/offers/NewOffer");

// Views - Communities
const Communities = () => import("@/views/communities/Communities");
const Community = () => import("@/views/communities/Community");
const NewCommunity = () => import("@/views/communities/NewCommunity");

// Views - reservations
const Reservations = () => import("@/views/reservations/Reservations");
const Reservation = () => import("@/views/reservations/Reservation");
const NewReservation = () => import("@/views/reservations/NewReservation");

// Views - dispatchers
const Dispatchers = () => import("@/views/dispatchers/Dispatchers");
const Dispatcher = () => import("@/views/dispatchers/Dispatcher");
const NewDispatcher = () => import("@/views/dispatchers/NewDispatcher");

// Views - add ons
const Addons = () => import("@/views/add-ons/Addons");
const Addon = () => import("@/views/add-ons/Addon");
const NewAddon = () => import("@/views/add-ons/NewAddon");

// Views - Clients
const Clients = () => import("@/views/clients/Clients");
const Client = () => import("@/views/clients/Client");
const NewClient = () => import("@/views/clients/NewClient");

// Views - merchant accounts
const Accounts = () => import("@/views/merchant-accounts/Accounts");
const Account = () => import("@/views/merchant-accounts/Account");
const NewAccount = () => import("@/views/merchant-accounts/NewAccount");

// Views - company profiles
const CompanyProfiles = () => import("@/views/company-profiles/CompanyProfiles");
const CompanyProfile = () => import("@/views/company-profiles/CompanyProfile");
const NewCompanyProfile = () => import("@/views/company-profiles/NewCompanyProfile");

// Views - statuses
const Statuses = () => import("@/views/statuses/Statuses");

// Views - job types
const JobTypes = () => import("@/views/job-types/JobTypes");
const JobType = () => import("@/views/job-types/JobType");
const NewJobType = () => import("@/views/job-types/NewJobType");

// Views - vehicle types
const VehicleTypes = () => import("@/views/vehicle-types/VehicleTypes");
const VehicleType = () => import("@/views/vehicle-types/VehicleType");
const NewVehicleType = () => import("@/views/vehicle-types/NewVehicleType");

// Views - fleet
const Vehicles = () => import("@/views/fleet/Vehicles");
const Vehicle = () => import("@/views/fleet/Vehicle");
const NewVehicle = () => import("@/views/fleet/NewVehicle");

// Views - Rates
const Rates = () => import("@/views/rate-generator/Rates");
const Rate = () => import("@/views/rate-generator/Rate");
const NewRate = () => import("@/views/rate-generator/NewRate");

// Views - Service Types
const ServiceTypes = () => import("@/views/service-types/ServiceTypes");
const ServiceType = () => import("@/views/service-types/ServiceType");
const NewServiceType = () => import("@/views/service-types/NewServiceType");

// Views - Taxes
const Taxes = () => import("@/views/taxes/Taxes");
const Tax = () => import("@/views/taxes/Tax");
const NewTax = () => import("@/views/taxes/NewTax");

// Views - Bonuses
const Bonuses = () => import("@/views/bonuses/Bonuses");
const Bonus = () => import("@/views/bonuses/Bonus");
const NewBonus = () => import("@/views/bonuses/NewBonus");



// Views - Increases and decreases (Overrides)
const Overrides = () => import("@/views/overrides/Overrides");
const Override = () => import("@/views/overrides/Override");
const NewOverride = () => import("@/views/overrides/NewOverride");

// Views - geofences
const Geofences = () => import("@/views/geofences/Geofences");
const Geofence = () => import("@/views/geofences/Geofence");
const NewGeofence = () => import("@/views/geofences/NewGeofence");

// Views - jobs
const Jobs = () => import("@/views/jobs/Jobs");
const Job = () => import("@/views/jobs/Job");

// Views - drivers
const Drivers = () => import("@/views/drivers/Drivers");
const Driver = () => import("@/views/drivers/Driver");
const NewDriver = () => import("@/views/drivers/NewDriver");

const CompanyDrivers = () => import("@/views/drivers/CompanyDrivers");
const CompanyDriver = () => import("@/views/drivers/CompanyDriver");
const NewCompanyDriver = () => import("@/views/drivers/NewCompanyDriver");
const DriverProfile = () => import("@/views/drivers/DriverProfile");
const OwnerOperators = () => import("@/views/drivers/OwnerOperators");
const PartnerSearch = () => import("@/views/users-linking/PartnerSearch")

// // Views - Pages
// const Page404 = () => import("@/views/pages/Page404");
// const Page500 = () => import("@/views/pages/Page500");
// const ForgotPassword = () => import("@/views/pages/ForgotPassword");
// const Login = () => import("@/views/pages/Login");
// const Register = () => import("@/views/pages/Register");

// Users
const Users = () => import("@/views/users/Users");
const User = () => import("@/views/users/User");
const NewUser = () => import("@/views/users/NewUser");
const UserProfile = () => import("@/views/users/UserProfile");

const UserLinkingDetail = () => import("@/views/users-linking/UserLinkingDetail")


const Page404 = () => import("@/views/pages/Page404");
const Page500 = () => import("@/views/pages/Page500");
const ForgotPassword = () => import("@/views/pages/ForgotPassword");
const Login = () => import("@/views/pages/Login");
const Register = () => import("@/views/pages/Register");




import { default as webRoutes } from '../web/routes';
let routeFiles = [];
const routes = routeFiles.concat(configRoutes());
//const routes = routeFiles.concat(configRoutesBeta());
Vue.use(Router);
const router = new Router({
  //mode: "hash", // https://router.vuejs.org/api/#mode
  mode: "history", // https://router.vuejs.org/api/#mode
  linkActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  routes,
});

export default router;

import firebase from "../Firebase";

router.beforeEach(async (to, from, next) => {
  console.log(to)
  let detailView = null
  for (const [key, value] of Object.entries(to.params)) {
    if(key === 'id') {
      detailView = true
    }
  }
  
  let title = detailView ? ` ${to.params.name} | ${to.meta.label}`: to.meta.label
  if(to.name == 'Reservation') {
    title = `Reservation ${to.params.readableId}`
  } else if(to.name == 'Rate') {
    title = `${to.params.type} ${to.meta.label} `
  }
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (requiresAuth && !(await firebase.getCurrentUser())) {
    next("/w/login");
  } else {
    if(title) {
      document.title = title + ' - Up limo'
    }

    if(to.name === 'Base') {
      next("/w");
    } else if(to.name === 'Login' || to.name === 'Register') {
      //next("/app/dashboard");
      next();
    } else {
      next();
    }
  }
});

function configRoutes() {
  return [
    {
      path:'',
      component: {
        render(c) {
          return c("router-view");
        },
      },
      name: 'Base',
      meta: {
        breadcrumb: 'Base'
      },

      children: [
        {
          path: "/app",
          redirect: "/app/dashboard",
          name: "App",
          meta: {
            breadcrumb: "App"
          },
          component: TheContainer,
          children: [
            {
              path: "clients",
              name:"Clients",
              meta: {
                label: "Clients",
                breadcrumb: "Clients"
              },
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "",
                  name: "Clients List",
                  component: Clients,
                  meta: {
                    requiresAuth: true,
                    label: "Clients"
                  },
                },
                {
                  path: "new",
                  name: "NewClient",
                  meta: {
                    label: "Create client",
                    requiresAuth: true,
                  },
                  component: NewClient,
                },
                {
                  path: ":name/:id",
                  meta: {
                    label: "Client Details",
                    requiresAuth: true,
                    breadcrumb: routeParams => `${routeParams.name}`
                  },
                  name: "Client",
                  component: Client,
    
                 
                },
              ],
            },
            {
              path: "communities",
              meta: {
                label: "Communities",
    
                breadcrumb: "Communities"
              },
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "",
                  name: "Communities List",
                  component: Communities,
                  meta: {
                    requiresAuth: true,
                    label: "Communities list"
                  },
                },
                {
                  path: "new",
                  name: "NewCommunity",
                  meta: {
                    label: "New community",
                  },
                  component: NewCommunity,
                },
                {
    
                  path: ":name/:id",
                  meta: {
                    breadcrumb: routeParams => `${routeParams.name}`,
                    label: 'Community'
                  },
                  // meta: (route) => ({
    
                  //   requiresAuth: true,
                  //   label: route.params.name + ' details',
                  //   breadcrumb: route.params.name
                    
                  // }),
                  // meta: {
                  //   breadcrumb() {
                  //     const { name } = this.$route;
                  //     const label = this.$route.params.name
                  //     return {
                  //       label: label,
                        
                  //     };
                  //   },
                  //   label: 'Comunnity details'
                  //   breadcrumb: routeParams => `${routeParams.name}`
                  // },
                  name: "Community",
                  component: Community,
                },
              ],
            },
            {
              path: "dashboard",
              name: "Dashboard",
              component: Dashboard,
              meta: {
                requiresAuth: true,
                label: "Dashboard"
              },
            },
            {
              path: "user-settings",
              name: "UserSettings",
              component: UserSettings,
              meta: {
                requiresAuth: true,
              },
            },
            {
              path: "fixer",
              name: "Fixer",
              component: Fixer,
              meta: {
                requiresAuth: true,
              },
            },
            {
              path: "driver-profile",
              name: "DriverProfile",
              component: DriverProfile,
              meta: {
                requiresAuth: true,
                label: "Driver profile",
                breadcrumb: "Driver profile"
              },
            },
            {
              path: "user-profile",
              name: "UserProfile",
              component: UserProfile,
              meta: {
                requiresAuth: true,
                label: "User profile",
                breadcrumb: "User profile"
              },
            },
            {
              path: "partner-search",
              name: "PartnerSearch",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              meta: {
                requiresAuth: true,
                label: "Partner search",
                breadcrumb: "Partner search"
              },
              children: [
                {
                  path: "",
                  name: "List",
                  component: PartnerSearch,
                  meta: {
                    label: "Partner search",
                    breadcrumb: "",
                    requiresAuth: true,
                  },
                },
                {
                  path: ":role/:uid/:email",
                  meta: {
                    label: "User Details",
                    requiresAuth: true,
                    breadcrumb: routeParams => `${routeParams.uid}`
                  },
                  name: "User details",
                  component: UserLinkingDetail,
                },
              ]
            },
            {
              path: "reservations",
              meta: {
                label: "Reservations",
                requiresAuth: true,
              },
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "",
                  name: "Reservations",
                  component: Reservations,
                  meta: {
                    requiresAuth: true,
                    label: 'Reservations'
                  },
                },
                {
                  path: "status/:status",
                  meta: {
                    label: "Status list",
                    requiresAuth: true,
                  },
                  name: "FilteredReservations",
                  component: Reservations,
                },
                {
                  path: "new",
                  name: "NewReservation",
                  meta: {
                    label: "New reservation",
                    requiresAuth: true,
                  },
                  component: NewReservation,
                },
                {
                  path: ":readableId/:id",
                  meta: {
                    label: "Reservation Details",
                    requiresAuth: true,
                    breadcrumb: routeParams => `${routeParams.readableId}`
                  },
                  name: "Reservation",
                  component: Reservation,
                },
              ],
            },
            {
              path: "offers",
              meta: {
                label: "Offers",
                requiresAuth: true,
              },
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "",
                  name: "Offers List",
                  component: Offers,
                  meta: {
                    requiresAuth: true,
                  },
                },
                {
                  path: "new",
                  name: "NewOffer",
                  meta: {
                    label: "New offer",
                    requiresAuth: true,
                  },
                  component: NewOffer,
                },
                {
                  path: ":id",
                  meta: {
                    label: "Offer Details",
                    requiresAuth: true,
                  },
                  name: "Offer",
                  component: Offer,
                },
              ],
            },
            {
              path: "add-ons",
              meta: {
                label: "Addons",
                breadcrumb: "Addons"
              },
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "",
                  name: "Addons List",
                  component: Addons,
                  meta: {
                    requiresAuth: true,
                    label: "Add ons"
                  },
                },
                {
                  path: "new",
                  name: "NewAddon",
                  meta: {
                    label: "Create add on",
                    requiresAuth: true,
                  },
                  component: NewAddon,
                },
                {
                  path: ":name/:id",
                  meta: {
                    label: "Add on Details",
                    requiresAuth: true,
                    breadcrumb: routeParams => `${routeParams.name}`
                  },
                  name: "Addon",
                  component: Addon,
    
                 
                },
              ],
            },
            {
              
              path: "merchant-accounts",
              meta: {
                label: "Merchant accounts",
                breadcrumb: "Merchant accounts"
              },
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "",
                  name: "Accounts List",
                  component: Accounts,
                  meta: {
                    requiresAuth: true,
                    label: "Merchant accounts",
                  },
                },
                {
                  path: "new",
                  name: "NewAccount",
                  meta: {
                    label: "Create account",
                    requiresAuth: true,
                  },
                  component: NewAccount,
                },
                {
                  path: ":name/:id",
                  meta: {
                    label: "Account Details",
                    requiresAuth: true,
                    breadcrumb: routeParams => `${routeParams.name}`
                  },
                  name: "Account",
                  component: Account,
    
                 
                },
              ],
            },
            {
              path: "statuses",
              meta: {
                label: "Statuses",
              },
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "",
                  name: "Statuses List",
                  component: Statuses,
                  meta: {
                    requiresAuth: true,
                  },
                },
              ],
            },
            {
              path: "vehicle-types",
              meta: {
                label: "Vehicle Types",
                breadcrumb: "Vehicle Types",
              },
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "",
                  name: "Vehicle type List",
                  component: VehicleTypes,
                  meta: {
                    requiresAuth: true,
                    label: "Vehicle Types",
                  },
                },
                {
                  path: "new",
                  name: "NewVehicleType",
                  meta: {
                    label: "Create vehicle type",
                  },
                  component: NewVehicleType,
                },
                {
                 path: ":name/:id",
                  meta: {
                    label: "Vehicle Type Details",
                    breadcrumb: routeParams => `${routeParams.name}`
                  },
                  name: "VehicleType",
                  component: VehicleType,
                },
              ],
            },
            {
              path: "fleet",
              meta: {
                label: "Fleet",
                breadcrumb: "Fleet",
              },
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "",
                  name: "Vehicles",
                  component: Vehicles,
                  meta: {
                    requiresAuth: true,
                    label: "Fleet",
                  },
                },
                {
                  path: "new",
                  name: "NewVehicle",
                  meta: {
                    label: "Create vehicle",
                  },
                  component: NewVehicle,
                },
                {
                 path: ":name/:id",
                  meta: {
                    label: "Vehicle",
                    breadcrumb: routeParams => `${routeParams.name}`
                  },
                  name: "Vehicle",
                  component: Vehicle,
                },
              ],
            },
            {
              path: "taxes-and-fees",
              meta: {
                label: "Subcharges, Taxes and Fees",
    
                breadcrumb: "Subcharges, Taxes and Fees"
              },
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "",
                  name: "Taxes List",
                  component: Taxes,
                  meta: {
                    requiresAuth: true,
                    label: "Subcharges, Taxes and Fees",
                  },
                },
                {
                  path: "new",
                  name: "NewTax",
                  meta: {
                    label: "Create tax",
                  },
                  component: NewTax,
                },
                {
    
                  path: ":name/:id",
                  meta: {
                    label: "Tax Details",
                    breadcrumb: routeParams => `${routeParams.name}`
                  },
                  name: "Tax",
                  component: Tax,
                },
              ],
            },
            {
              path: "bonuses-and-discounts",
              meta: {
                label: "Bonuses and discounts",
    
                breadcrumb: "Bonuses and discounts"
              },
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "",
                  name: "Bonuses List",
                  component: Bonuses,
                  meta: {
                    requiresAuth: true,
                    label: "Bonuses and discounts",
                  },
                },
                {
                  path: "new",
                  name: "NewBonus",
                  meta: {
                    label: "Create bonus or discount",
                  },
                  component: NewBonus,
                },
                {
    
                  path: ":name/:id",
                  meta: {
                    label: "Bonus Details",
                    breadcrumb: routeParams => `${routeParams.name}`
                  },
                  name: "Bonus",
                  component: Bonus,
                },
              ],
            },
            {
              path: "increases-and-decreases",
              meta: {
                label: "Increases and decreases",
    
                breadcrumb: "Increases and decreases"
              },
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "",
                  name: "Increases and decreases List",
                  component: Overrides,
                  meta: {
                    requiresAuth: true,
                    label: "Increases and decreases",
                  },
                },
                {
                  path: "new",
                  name: "NewOverride",
                  meta: {
                    label: "Create increases or decrease",
                  },
                  component: NewOverride,
                },
                {
    
                  path: ":name/:id",
                  meta: {
                    label: "Increase or decrease Details",
                    breadcrumb: routeParams => `${routeParams.name}`
                  },
                  name: "Override",
                  component: Override,
                },
              ],
            },
            {
              path: "rates",
              meta: {
                label: "Rate generator",
                breadcrumb: "Rate generator"
              },
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "",
                  name: "Rate List",
                  component: Rates,
                 
                  meta: {
                    requiresAuth: true,
                    label: "Rate generator"
                    
                  },
                },
                {
                  path: "new",
                  name: "NewRate",
                  meta: {
                    label: "Create rate",
                  },
                  component: NewRate,
                },
                {
                  path: ":type/:id",
                  
                  meta: {
                    breadcrumb: routeParams => `${routeParams.type}`,
                    label: 'Rate'
                  },
                  name: "Rate",
                  component: Rate,
                },
              ],
            },
            {
              path: "service-types",
              meta: {
                label: "Service Types",
                breadcrumb: "Service Types",
              },
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "",
                  name: "Service Types List",
                  component: ServiceTypes,
                  meta: {
                    requiresAuth: true,
                    label: "Service types"
                  },
                },
                {
                  path: "new",
                  name: "NewServiceType",
                  meta: {
                    label: "Create service type",
                  },
                  component: NewServiceType,
                },
                {
                  path: ":name/:id",
                  meta: {
                    label: "Service Type Details",
                    breadcrumb: routeParams => `${routeParams.name}`
                  },
                  name: "ServiceType",
                  component: ServiceType,
                },
              ],
            },       
            {
              path: "geofences",
              meta: {
                label: "Geofences",
                breadcrumb: "Geofences",
              },
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "",
                  name: "Geofences List",
                  component: Geofences,
                  meta: {
                    requiresAuth: true,
                    label: "Geofences",
                  },
                },
                {
                  path: "new",
                  name: "NewGeofence",
                  meta: {
                    label: "Create geofence",
                  },
                  component: NewGeofence,
                },
                {
                  path: ":name/:id",
                  meta: {
                    label: "Geofence Details",
                    breadcrumb: routeParams => `${routeParams.name}`
                  },
                  name: "Geofence",
                  component: Geofence,
                },
              ],
            },
            {
              path: "associated-users",
              meta: {
                label: "Associated users",
                breadcrumb: "Associated users",
                // breadcrumb() {
                //   const { name } = this.$route;
                //   //return `name "${name}" of context route`;
                //   return {
                //     text: "Associated users",
                //   }
                // }
              },
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  
                  path: "owner-operators",
                  meta: {
                    label: "Owner operators",
                    breadcrumb: "Owner operators",
                  },
                  component: {
                    render(c) {
                      return c("router-view");
                    },
                  },
                  children: [
                    {
                      path: "",
                      name: "List",
                      component: OwnerOperators,
                      meta: {
                        label: "",
                        breadcrumb: "",
                        requiresAuth: true,
                      },
                    },
    
                    {
                      path: ":role/:uid/:email",
                      meta: {
                        label: "User Details",
                        requiresAuth: true,
                        breadcrumb: routeParams => `${routeParams.uid}`
                      },
                      name: "User details",
                      component: UserLinkingDetail,
                    },
                  ] 
                },
                {
                  path: "independent-drivers",
                  meta: {
                    label: "Independent drivers",
                    breadcrumb: "Independent drivers",
                  },
                  component: {
                    render(c) {
                      return c("router-view");
                    },
                  },
                  children: [
                    {
                      path: ":role/:uid/:email",
                      meta: {
                        label: "User Details",
                        requiresAuth: true,
                        breadcrumb: routeParams => `${routeParams.uid}`
                      },
                      name: "User details",
                      component: UserLinkingDetail,
                    },
                  ] 
                },
                {
                  path: "freelance-dispatchers",
                  meta: {
                    label: "Freelance dispatchers",
                    breadcrumb: "Freelance dispatchers",
                  },
                  component: {
                    render(c) {
                      return c("router-view");
                    },
                  },
                  children: [
                    {
                      path: ":role/:uid/:email",
                      meta: {
                        label: "User Details",
                        requiresAuth: true,
                        breadcrumb: routeParams => `${routeParams.uid}`
                      },
                      name: "User details",
                      component: UserLinkingDetail,
                    },
                  ] 
                },
                {
                  path: "affiliated-companies",
                  meta: {
                    label: "Affiliated companies",
                    breadcrumb: "Affiliated companies",
                  },
                  component: {
                    render(c) {
                      return c("router-view");
                    },
                  },
                  children: [
                    {
                      path: ":role/:uid/:email",
                      meta: {
                        label: "User Details",
                        requiresAuth: true,
                        breadcrumb: routeParams => `${routeParams.uid}`
                      },
                      name: "User details",
                      component: UserLinkingDetail,
                    },
                  ] 
                }
              ]
            },
            {
              path: "company-profiles",
              meta: {
                label: "Company Profiles",
                breadcrumb: "Company Profiles",
              },
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "",
                  name: "Company Profiles List",
                  component: CompanyProfiles,
                  meta: {
                    requiresAuth: true,
                    label: "Company Profiles",
                  },
                },
                {
                  path: "new",
                  name: "NewCompanyProfile",
                  meta: {
                    label: "Create company profile",
                  },
                  component: NewCompanyProfile,
                },
                {
                  path: ":name/:id",
                  meta: {
                    label: "Company Profile Details",
                    breadcrumb: routeParams => `${routeParams.name}`
                  },
                  name: "CompanyProfile",
                  component: CompanyProfile,
                },
              ],
            },
            {
              path: "job-types",
              meta: {
                label: "Job Types",
              },
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "",
                  name: "Job types List",
                  component: JobTypes,
                  meta: {
                    requiresAuth: true,
                  },
                },
                {
                  path: "new",
                  name: "NewJobType",
                  meta: {
                    label: "New job type",
                  },
                  component: NewJobType,
                },
                {
                  path: ":id",
                  meta: {
                    label: "Job type details",
                  },
                  name: "JobType",
                  component: JobType,
                },
              ],
            },
            {
              path: "jobs",
              meta: {
                label: "Jobs",
              },
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "",
                  name: "Jobs List",
                  component: Jobs,
                },
                {
                  path: ":id",
                  meta: {
                    label: "Job Details",
                  },
                  name: "Job",
                  component: Job,
                },
              ],
            },
            {
              path: "drivers",
              meta: {
                label: "Drivers",
                breadcrumb: "Drivers",
              },
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "company-drivers",
                  meta: {
                    label: "Company Drivers",
                    breadcrumb: "Company drivers",
                  },
                  component: {
                    render(c) {
                      return c("router-view");
                    },
                  },
                  children: [
                    
                    {
                      path: "",
                      name: "List",
                      component: CompanyDrivers,
                      meta: {
                        label: "",
                        breadcrumb: "",
                        requiresAuth: true,
                      },
                    },
                    {
                      path: "new",
                      name: "New company driver",
                      meta: {
                        label: "Create company driver",
                        requiresAuth: true,
                      },
                      component: NewCompanyDriver,
                    },
                    {
                      path: ":id",
                      meta: {
                        label: "Company driver Details",
                        requiresAuth: true,
                        breadcrumb: routeParams => `${routeParams.id}`
                      },
                      name: "Company driver",
                      component: CompanyDriver,
                    },
                  ],
                },
                
              ]
            },   
            {
              path: "users",
              meta: {
                label: "Users",
              },
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "new",
                  name: "New",
                  component: NewUser,
                },
                {
                  path: "",
                  name: "User List",
                  component: Users,
                },
                {
                  path: "dispatchers",
                  meta: {
                    label: "Dispatchers",
                  },
                  component: {
                    render(c) {
                      return c("router-view");
                    },
                  },
                  children: [
                    {
                      path: "",
                      name: "Dispatcher List",
                      component: Dispatchers,
                      meta: {
                        requiresAuth: true,
                      },
                    },
                    {
                      path: "new",
                      name: "NewDispatcher",
                      meta: {
                        label: "Create dispatcher",
                        requiresAuth: true,
                      },
                      component: NewDispatcher,
                    },
                    {
                      path: ":id",
                      meta: {
                        label: "Dispatcher Details",
                        requiresAuth: true,
                      },
                      name: "Dispatcher",
                      component: Dispatcher,
                    },
                  ],
                },
                {
                  path: "drivers",
                  meta: {
                    label: "Drivers",
                  },
                  component: {
                    render(c) {
                      return c("router-view");
                    },
                  },
                  children: [
                    {
                      path: "",
                      name: "Drivers List",
                      component: Drivers,
                    },
                    {
                      path: "new",
                      name: "NewDriver",
                      meta: {
                        label: "Create driver",
                        requiresAuth: true,
                      },
                      component: NewDriver,
                    },
                    {
                      path: ":id",
                      meta: {
                        label: "Driver Details",
                      },
                      name: "Driver",
                      component: Driver,
                    },
                  ],
                }, 
                {
                  path: ":id",
                  meta: {
                    label: "User Details",
                  },
                  name: "User",
                  component: User,
                },
              ],
            },
            
          ],
        },
        {
          path: "/w",
          name: "Web",
          component: WebContainer,
          children: webRoutes
          
        },

      ]
    },
    
  ];
}

