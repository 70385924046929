// Views - Pages
const Page404 = () => import("@/views/pages/Page404");
const Page500 = () => import("@/views/pages/Page500");
const ForgotPassword = () => import("@/views/pages/ForgotPassword");
const Login = () => import("@/views/pages/Login");
const Register = () => import("@/views/pages/Register");

export default [
    {
      path:'',
      component: () => import("@/web/pages/Home"),
      name:'Home'
    },
    {
      path:'privacy-policy',
      component: () => import("@/web/pages/PrivacyPolicy"),
      name:'PrivacyPolicy'
    },
    {
      path:'web-terms-of-use',
      component: () => import("@/web/pages/WebTermsOfUse"),
      name:'WebTermsOfUse'
    },
    {
      path:'terms-and-conditions',
      component: () => import("@/web/pages/TermsAndConditions"),
      name:'TermsAndConditions'
    },
    {
      path: "404",
      name: "Page404",
      component: Page404,
    },
    {
      path: "500",
      name: "Page500",
      component: Page500,
    },
    {
      path: "login",
      name: "Login",
      meta: {
        label: "Login",

      },
      component: Login,
    },
    {
      path: "forgot-password",
      name: "ForgotPassword",
      meta: {
        label: "Forgot password",
      },
      component: ForgotPassword,
    },
    {
      path: "register",
      name: "Register",
      meta: {
        label: "Register",
      },
      component: Register,
    },
    {
      path:'contact-us',
      component: () => import("@/web/pages/Contact")
    },
    {
      path:'documentation',
      component: () => import("@/web/pages/Documentation")
    },
    {
      path:'pricing',
      component: () => import("@/web/pages/Pricing")
    },
    
];